<template lang="html">
<section id="impressum">
  <div class="container-fluid">
    <article class="rechtliches-article">
      <vue-simple-markdown :source="impressum.impressum"></vue-simple-markdown>
    </article>
  </div>
</section>
</template>

<script>
import gql from 'graphql-tag';

export default {
  name: 'Impressum',
  data() {
    return {
      impressum: [],
    };
  },
  apollo: {
    impressum: gql `
      query{
        impressum{
          impressum
        }
      }
      `
  }
}
</script>
