<template lang="html">
  <section id="datenschutz">
    <div class="container-fluid">
      <article class="rechtliches-article">
        <vue-simple-markdown :source="privacy.privacy"></vue-simple-markdown>
      </article>
    </div>
  </section>
</template>

<script>
import gql from 'graphql-tag';

export default {
  name: 'Datenschutz',
  data(){
    return{
      privacy: []
    }
  },
  apollo: {
    privacy: gql `
      query{
        privacy{
          privacy
        }
      }
      `
  }
}
</script>
