<template>
  <main>
    <Nav />
    <Landingsection />
    <img id="ellipse2" src="../src/assets/media/ellipse2.svg" alt="Ellipse 2">
    <About />
    <Newssection />
    <div style="position:relative;">
      <Kurse />
      <img id="logo1Freigestellt" src="../src/assets/media/Yoga-Logo_1_freigestellt.svg" alt="Logo">
      <PersonalYoga />
    </div>
    <Hormonyoga />
    <Workshop />
    <Kontakt />
  </main>
</template>

<script>
  import Nav from "./components/Nav.vue";
  import Landingsection from "./components/Landingsection.vue";
  import About from "./components/About.vue";
  import Newssection from "./components/Newssection.vue";
  import Kurse from "./components/Kurse.vue";
  import PersonalYoga from "./components/PersonalYoga.vue";
  import Hormonyoga from "./components/Hormonyoga.vue";
  import Workshop from "./components/Workshop.vue";
  import Kontakt from "./components/Kontakt.vue";

  export default {

    name: 'App',
    components: {
      Nav,
      Landingsection,
      About,
      Newssection,
      Kurse,
      PersonalYoga,
      Hormonyoga,
      Workshop,
      Kontakt
    }
  }
</script>
