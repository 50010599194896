import Vue from 'vue'
import VueRouter from 'vue-router'
import Impressum from '@/components/Impressum'
import Datenschutz from '@/components/Datenschutz'
import App from './App.vue'

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes:[
    {
      path: '/',
      component: App
    },
    {
      path: '/impressum',
      component: Impressum
    },
    {
      path: '/datenschutz',
      component: Datenschutz
    }
  ]
});

export default router;
