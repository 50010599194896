<template lang="html">
  <section id="hormonyoga">
    <span class="background-headline-small"><div id="hormonyogaH">H</div>ormonyoga</span>
    <img id="ellipse7" src="../assets/media/ellipse7.svg" alt="Ellipse 7">
    <img id="blaueKante" src="../assets/media/BlaueKante.png" alt="Blaue Kante">
    <div class="container-fluid">
      <h2 class="display-2">Hormonyoga</h2>
      <h3>{{hormonyoga.subtitle}}</h3>
      <vue-simple-markdown class="hormonyoga-richtext" :source="hormonyoga.textblock1"></vue-simple-markdown>
      <vue-simple-markdown class="hormonyoga-richtext" :source="hormonyoga.liste1"></vue-simple-markdown>
      <vue-simple-markdown class="hormonyoga-richtext" :source="hormonyoga.liste2"></vue-simple-markdown>
      <div class="text-center"><vue-simple-markdown class="hormonyoga-richtext" id="hormonyoga-hinweis" :source="hormonyoga.hinweis"></vue-simple-markdown></div>
      <vue-simple-markdown class="hormonyoga-richtext" :source="hormonyoga.textblock2"></vue-simple-markdown>
    </div>
  </section>
</template>

<script>
import gql from 'graphql-tag';

export default {
  name: 'Hormonyoga',
  data() {
    return {
      hormonyoga: [],
      url: process.env.VUE_APP_STRAPI_API_URL
    };
  },
  apollo: {
    hormonyoga: gql `
    query{
      hormonyoga{
        subtitle
        textblock1
        textblock2
        liste1
        liste2
        hinweis
      }
    }
    `
  }
}
</script>
