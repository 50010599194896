<template>
<section id="kurse">
  <div class="kurse-bg">
    <span class="background-headline kurse-headline">Kurse</span>
    <img id="weisseKante" src="../assets/media/WeisseKante.png" alt="Weiße Kante">
    
    <div class="container-fluid">
      <h2 class="display-2">Kurse</h2>
      <img id="ellipse5" src="../assets/media/ellipse5.svg" alt="Ellipse">
      <article class="kurse-text-block">
        <p>
          <vue-simple-markdown :source="kurse.textblock1"></vue-simple-markdown>
        </p>
        <p>
          <vue-simple-markdown :source="kurse.textblock2"></vue-simple-markdown>
        </p>
      </article>

      <div class="kurse-kursplan">
        <h3 class="display-dark-blue kurse-kursplan-headline">Kursplan</h3>
        <div v-for="tag in tage" :key="tag">
          <div v-if="filterKursplanByDay(tag).length >= 1">
            <div class="kurse-kursplan-container">
              <h3 class="kurse-kursplan-weekday">{{tag}}</h3>
              <div class="kurse-kursplan-content row" v-for="kursplan in filterKursplanByDay(tag)" :key="kursplan.id">

                <div class="kurse-kursplan-left col">
                  <div class="kursplan-time-container">
                    <b>
                      <p class="kurse-kursplan-time"><span>{{kursplan.uhrzeitVon.slice(0,5) }}</span> - <span>{{kursplan.uhrzeitBis.slice(0,5)}}</span> Uhr</p>
                    </b>
                    <b>
                      <p>Kursleitung: <span>{{kursplan.kursleitung}}</span></p>
                    </b>
                  </div>
                </div>

                <div class="kurse-kursplan-beschreibung col">
                  <div class="kursplan-beschreibung-container">
                    <p>{{kursplan.beschreibung}}</p>
                  </div>
                </div>

                <div class="col" id="kursplanColDatum">
                  <div class="kursplan-datum-container" style="display: inline-flex;">
                    <div class="kurse-kursplan-datum-span-container">
                      <div v-if="formatDate(kursplan.datumVon) != null">
                        <p class="kurse-kursplan-datum-span">vom</p>
                      </div>
                      <div v-if="formatDate(kursplan.datumBis) != null">
                        <p class="kurse-kursplan-datum-span">bis</p>
                      </div>
                    </div>

                    <div>
                      <div v-if="formatDate(kursplan.datumVon) != null">
                        <p class="kurse-kursplan-datum">{{formatDate(kursplan.datumVon)}}</p>
                      </div>
                      <div v-if="formatDate(kursplan.datumBis) != null">
                        <p class="kurse-kursplan-datum">{{formatDate(kursplan.datumBis)}}</p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <img id="ellipse6" src="../assets/media/ellipse6.svg" alt="Ellipse">

      <div class="kurse-gebuehr">

        <div class="kurse-gebuehr-left">
          <article class="kurse-gebuehr-textblock">
            <p>
              <vue-simple-markdown :source="kurse.textblock3"></vue-simple-markdown>
            </p>
          </article>
        </div>
      </div>
      <div class="row slideshowDesktop" id="kurseGebuehrSlideshow">
        <div class="col">
          <div class="kurse-gebuehr-container">
            <h3 class="display-dark-blue kurse-gebuehr-headline">Gebühr</h3>
            <div class="kurse-gebuehr-content" v-for="fee in kurse.fees" :key="fee.id">
              <p>{{fee.Art}}</p>

              <p class="kurse-gebuehr-preis">{{formatFee(fee.Preis)}}</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="Slideshow">
            <carousel :perPage="3" :speed="1000" :autoplayTimeout="5000" :loop="true" :scrollPerPage="true" :autoplay="true">
              <slide class="" v-for="bild in kurse.Slideshow" :key="bild.id">
                <i class="img-expand fas fa-expand"></i>
                <img v-on:click="modal(bild)" class="slide-img" :src="url + bild.url" :alt="bild.alternativeText">
              </slide>
            </carousel>
          </div>
        </div>
      </div>
      <div class="slideshowMobile">
        <div class="kurse-gebuehr-container">
          <h3 class="display-dark-blue kurse-gebuehr-headline">Gebühr</h3>
          <div class="kurse-gebuehr-content" v-for="fee in kurse.fees" :key="fee.id">
            <p>{{fee.Art}}</p>

            <p class="kurse-gebuehr-preis">{{formatFee(fee.Preis)}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="Slideshow slideshowMobile">
      <carousel :perPage="1" :autoplayTimeout="5000" :loop="true" :scrollPerPage="true" :autoplay="true">
        <slide class="" v-for="bild in kurse.Slideshow" :key="bild.id">
          <img class="slide-img" :src="url + bild.url" :alt="bild.alternativeText">
        </slide>
      </carousel>
    </div>
  </div>

</section>
</template>

<script>
import {
  Carousel,
  Slide
} from 'vue-carousel';
import gql from 'graphql-tag';

export default {
  name: 'Kurse',

  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      kurse: [],
      tage: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
      url: process.env.VUE_APP_STRAPI_API_URL
    };
  },
  methods: {
    formatDate(datum) {
      var monate = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
      if (datum == null) {
        return
      }
      var date = new Date(datum)
      // return date.getDate() + '. ' + monate[(date.getMonth())] + ' ' + date.getFullYear()
      return date.getDate() + '. ' + monate[(date.getMonth())]
    },
    formatFee(preis) {
      if (preis == 0) {
        preis = 'Kostenlos'
      } else {
        preis = preis.toFixed(2) + '€'
      }
      return preis
    },
    filterKursplanByDay(day) {
      return this.kurse.Kursplan.filter(Kursplan => Kursplan.weekday.Day == day)
    },
    modal: function(bild) {
      this.$modal.show({
        template: `
        <div>
          <i @click="$emit('close')" class="img-close fas fa-times"></i>
          <img class="imgModal" :src="dynUrl" :alt="altText">
        </div>
        `,
        props: ['dynUrl']
      }, {
        dynUrl: this.url + bild.url,
        altText: bild.alternativeText,
      }, {
        height: 'auto',
        width: (((bild.width / 10) * 1.5) / 1920) * 100 + '%'
        // width: '46%'
        // width: '31.25%'
      }, {
        'before-open': this.beforeOpen,
        'before-close': this.beforeClose
      })
    },
    beforeOpen() {
      console.log('Opening...');
      document.documentElement.style.overflow = 'hidden';
    },
    beforeClose() {
      console.log('Closing...')
      document.documentElement.style.overflow = 'auto';
    }
  },
  apollo: {
    kurse: gql `
      query{
        kurse{
          textblock1
          textblock2
          textblock3
          Slideshow{
            id
            url
            alternativeText
            width
          }
          fees{
            id
            Art
            Preis
          }
          Kursplan {
            __typename
            ... on ComponentKursKurs{
              id
              beschreibung
              uhrzeitVon
              uhrzeitBis
              datumVon
              datumBis
              kursleitung
              weekday{
                Day
              }
            }
          }
        }
      }
      `
  }
};
</script>
