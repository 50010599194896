<template>
  <section id="News" v-if="filterNachrichtByActive().length >= 1">
    <div class="news-bg">
      <span class="background-headline">News</span>
      <div class="container-fluid">
        <h2 class="display-2">News</h2>

        <div v-for="nachricht in filterNachrichtByActive()" :key="nachricht.id">
          <div class="container-news">
            <div class="container-news-header">
              <p class="news-date">{{nachricht.Zeitraum}}</p>
              <h3 class="display-dark-blue news-header">{{nachricht.titel}}</h3>
            </div>
            <div class="container-news-content">
              <p>{{nachricht.inhalt}}</p>
            </div>
          </div>
        </div>

      </div>
      <img id="ellipse4" src="../assets/media/ellipse4.svg" alt="Ellipse 4">
    </div>
  </section>
</template>

<script>
  import gql from 'graphql-tag';

  export default {
    name: 'Newssection',
   
    data() {
      return {
        nachrichts: [],
        url: process.env.VUE_APP_STRAPI_API_URL
      };
    },
    methods: {
      filterNachrichtByActive() {
        return this.nachrichts.filter(nachrichts => nachrichts.aktiv == true)
      }
    },
    apollo: {
      nachrichts: gql `
      query {
        nachrichts{
          id
          titel
          inhalt
          Zeitraum
          aktiv
        }
      }
      `
    }
  };
</script>