<template>
<section id="kontakt">
  <span class="background-headline-small">Kontakt</span>
  <div class="kontakt-bg">
    <div class="container-fluid">
      <h2 class="display-2">Kontakt</h2>
      <p class="text-center kontakt-info">{{kontakt.kontaktinfo}}</p>
      <div class="row">
        <div class="col kontakt-analog-container">
          <p class="kontakt-analog"><i class="kontakt-icon fas fa-phone-alt"></i> {{kontakt.Telefonnummer}}</p>
          <p class="kontakt-analog"><i class="kontakt-icon fas fa-envelope"></i> {{kontakt.email}}</p>
          <p class="kontakt-analog"><i class="kontakt-icon fas fa-map-marked-alt"></i> {{kontakt.adresse.postleitzahl}} {{kontakt.adresse.Ort}} <br>
            {{kontakt.adresse.Strasse}} {{kontakt.adresse.Hausnummer}}</p>
          <p v-if="kontakt.instagram" class="kontakt-analog"><i class="kontakt-icon fab fa-instagram"></i> {{kontakt.instagram}}</p>
        </div>
        <div class="col">
          <div class="kontakt-form container">
            <h3 class="kontakt-form-headline">Kontaktformular</h3>
            <div class="contact-form-group" data-validate="Dieses Feld darf nicht leer sein" :class="{ 'alert-validate-input': this.formStatus && $v.name.$invalid }">
              <label>Name</label><br>
              <input type="text" v-model="$v.name.$model"><br>
            </div>
            <div class="contact-form-group" data-validate="Gültige E-Mail-Adresse ist: wx@y.z" :class="{ 'alert-validate-input': this.formStatus && $v.customerEmail.$invalid }">
              <label>E-Mail</label><br>
              <input type="email" v-model="$v.customerEmail.$model"><br>
            </div>
            <div class="contact-form-group" data-validate="Dieses Feld darf nicht leer sein" :class="{ 'alert-validate-textarea': this.formStatus && $v.message.$invalid }">
              <label>Nachricht</label><br>
              <textarea v-model="$v.message.$model" rows="8" cols="80"></textarea><br>
            </div>
            <p>Alle Felder sind Pflichtfelder. Indem du auf Absenden klickst, bestätigst du, dass du unsere <router-link to="/datenschutz" title="Datenschutzerklärung">Datenschutzrichtlinien</router-link> akzeptierst.</p>
            <div class="button-wrapper text-center">
              <button id="contactSubmitBtn" type="button" name="button" v-on:click="sendMail">Absenden</button>
              <div id="contactloadingBtn" style="display: none; line-height: 5rem; margin-top: .8rem;">
                <div class="spinner-grow" style="height: 3rem; width: 3rem;" role="status"><span class="sr-only">Lade...</span></div>
              </div>
            </div>
            <p class="alert alert-success" v-if="mailSuccess && !mailError">Nachricht wurde erfolgreich verschickt.</p>
            <p class="alert alert-danger" v-if="mailError && !mailSuccess">Etwas ist bei dem versenden der Nachricht schief gelaufen. Bitte versuche es später erneut</p>
          </div>
        </div>
      </div>
      <div class="text-center">
        <div class="rechtliches">
          <router-view></router-view>
          <router-link id="imp" to="/impressum" title="Impressum">Impressum</router-link>
          <router-link id="daten" to="/datenschutz" title="Datenschutzerklärung">Datenschutzrichtlinien</router-link>
        </div>
        <div class="copyright">Copyright Babara Forster &copy; {{currentYear}}</div>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import gql from 'graphql-tag';
import axios from 'axios';
import {
  required,
  email
} from 'vuelidate/lib/validators'

export default {
  name: 'Kontakt',
  data() {
    return {
      kontakt: [],
      url: process.env.VUE_APP_STRAPI_API_URL,
      name: '',
      customerEmail: '',
      message: '',
      formStatus: false,
      mailSuccess: false,
      mailError: false,
      currentYear: new Date().getFullYear()
    };
  },
  apollo: {
    kontakt: gql `
      query{
        kontakt{
          kontaktinfo
          email
          Telefonnummer
          instagram
          adresse{
            Strasse
            Hausnummer
            Ort
            postleitzahl
          }
        }
      }
      `
  },
  validations: {
    name: {
      required
    },
    customerEmail: {
      required,
      email
    },
    message: {
      required
    }
  },
  methods: {
    async sendMail() {
      this.$v.$touch();
      if(this.$v.$invalid){
        this.formStatus = true;
      }else{
        document.getElementById("contactSubmitBtn").style.display = "none";
        document.getElementById("contactloadingBtn").style.display = "block";
        axios.post("https://api.barbara-yoga.de/api/mail", {
          companyEmail: this.kontakt.email,
          name: this.name,
          customerEmail: this.customerEmail,
          message: this.message
        }).then(response => {
          if (response.data.status === 'success') {
            this.mailSuccess = true;
            this.mailError = false;
            document.getElementById("contactSubmitBtn").style.display = "inline-block";
            document.getElementById("contactloadingBtn").style.display = "none";
          } else if (response.data.status === 'failed') {
            this.mailError = true;
            this.mailSuccess = false;
            document.getElementById("contactSubmitBtn").style.display = "inline-block";
            document.getElementById("contactloadingBtn").style.display = "none";
          }
        }).catch(() => {
          this.mailError = true;
          this.mailSuccess = false;
          document.getElementById("contactSubmitBtn").style.display = "inline-block";
          document.getElementById("contactloadingBtn").style.display = "none";
        });
      }
    }
  }
};
</script>
