<template>
  <section id="landingsection" v-bind:style="{'backgroundImage': 'url(' + url + willkomman.hintergrund.url +')'}">
    <div class="landingsection-wrapper">
      <img id="ellipse1" src="../assets/media/ellipse1.svg" alt="Ellipse">
      <div class="landingsection-content">
        <img class="logo" :src="url + willkomman.logo.url" alt="Logo">
        <div class="landingsection-text-container">
          <article class="landingsection-text container text-center">
            <h1>{{willkomman.titel}}</h1>
            <p>{{willkomman.inhalt}}</p>
          </article>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import gql from "graphql-tag";

  export default {
    name: "Landingsection",
    data() {
      return {
        willkomman: [],
        url: process.env.VUE_APP_STRAPI_API_URL
      };
    },
    apollo: {
      willkomman: gql `
      query{
        willkomman{
          titel
          inhalt
          hintergrund{
            url
          }
          logo{
            url
          }
        }
      }
    `
    }
  };
</script>
