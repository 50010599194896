<template>
<section id="about">
  <div class="about-bg">
    <span class="background-headline">About</span>
    <img id="ellipse3" src="../assets/media/ellipse3.svg" alt="Ellipse 3">
    <div class="container-fluid">
      <h2 class="display-2">About</h2>
      <div class="row">
        <div class="col">
          <article class="about-text-block textblock1">
            <p>
              <vue-simple-markdown :source="about.textblock1"></vue-simple-markdown>
            </p>
            <h3>{{about.schlagzeile1}}</h3>
            <p>
              <vue-simple-markdown :source="about.textblock2"></vue-simple-markdown>
            </p>
          </article>
        </div>
        <div class="col text-center">
          <img class="about-img" :src="url + about.cover.url" :alt="about.cover.alternativeText">
        </div>
      </div>
      <article class="about-text-block textblock3">
        <p>
          <vue-simple-markdown :source="about.textblock3"></vue-simple-markdown>
        </p>
        <h3>{{about.schlagzeile2}}</h3>
      </article>
      <div class="row">
        <div class="col about-img2-wrapper">
          <img class="about-img" :src="url + about.cover2.url" :alt="about.cover2.alternativeText">
        </div>
        <div class="col">
          <article class="about-text-block textblock4">
            <p>
              <vue-simple-markdown :source="about.textblock4"></vue-simple-markdown>
            </p>
          </article>
        </div>
      </div>
      <article class="about-text-block textblock5">
        <p>
          <vue-simple-markdown :source="about.textblock5"></vue-simple-markdown>
        </p>
      </article>
    </div>
  </div>
</section>
</template>

<script>
import gql from 'graphql-tag';

export default {
  name: 'About',
  data() {
    return {
      about: [],
      url: process.env.VUE_APP_STRAPI_API_URL
    };
  },
  apollo: {
    about: gql `
      query{
        about{
          cover{
            url
            alternativeText
          }
          cover2{
            url
            alternativeText
          }
          textblock1
          textblock2
          textblock3
          textblock4
          textblock5
          schlagzeile1
          schlagzeile2
        }
      }
      `
  }
};
</script>
