<template>
  <section id="workshop">
    <div class="workshop-bg">
      <span class="background-headline-small">Seminare und Workshops</span>
      <div class="container-fluid">
        <h2 class="display-2">Seminare und Workshops</h2>

        <div class="workshop-desktop">
          <div class="workshop-conatiner" v-for="workshop in workshops" :key="workshop.id">
            <img class="workshop-img" :src="url + workshop.bild.url" :alt="workshop.bild.alternativeText">
            <div class="workshop-content">
              <div class="workshop-headline">
                <h3 class="display-dark-blue">{{workshop.Titel}}</h3>
                <p class="workshop-seminarleiter">mit {{workshop.Seminarleiter}}</p>
              </div>
              <div class="workshop-date-container">
                <p class="workshop-date">{{formatDate(workshop.datum)}} von {{workshop.uhrzeitVon.slice(0,5)}} - {{workshop.uhrzeitBis.slice(0,5)}} Uhr</p>
              </div>
              <p class="workshop-beschreibung">{{workshop.beschreibung}}</p>
              <div class="workshop-footer">
                <p class="workshop-gebuehr">Gebühr: </p>
                <p class="workshop-preis">{{workshop.preis.toFixed(2).replace('.',',')}}€</p>
              </div>
            </div>
          </div>
        </div>

        <div class="workshop-mobile" id="workshop-mobile">
          <carousel class="workshop-carousel" :perPage="1" :scrollPerPage="true">
              <slide class="workshop-slide" v-for="workshop in workshops" :key="workshop.id">
                <div class="workshop-conatiner-mobile">
                  <img class="workshop-img" :src="url + workshop.bild.url" :alt="workshop.bild.alternativeText">
                  <div class="workshop-content">
                    <h3 class="display-dark-blue">{{workshop.Titel}}</h3>
                    <p class="workshop-seminarleiter">mit {{workshop.Seminarleiter}}</p>
                    <div class="workshop-date-container">
                      <p class="workshop-date">{{formatDate(workshop.datum)}}</p>
                      <p class="workshop-date">von {{workshop.uhrzeitVon.slice(0,5)}} - {{workshop.uhrzeitBis.slice(0,5)}} Uhr</p>
                    </div>
                    <p class="workshop-beschreibung">{{workshop.beschreibung}}</p>
                    <div class="workshop-footer">
                      <p class="workshop-gebuehr">Gebühr: </p>
                      <p class="workshop-preis">{{workshop.preis.toFixed(2).replace('.',',')}}€</p>
                    </div>
                  </div>
                </div>
              </slide>
          </carousel>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
  import { Carousel, Slide } from 'vue-carousel';
  import gql from 'graphql-tag';

  export default {
    name: 'Workshop',

    components: {
      Carousel,
      Slide
    },
    data() {
      return {
        workshops: [],
        url: process.env.VUE_APP_STRAPI_API_URL
      };
    },
    methods: {
      formatDate(datum) {
        var monate = ['Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember']
        var weekday = ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag']
        if(datum == null)
        {
          return
        }
        var date = new Date(datum)
        return weekday[date.getDay()] + ', ' + date.getDate() + '. ' + monate[(date.getMonth())] + ' ' + date.getFullYear()
      },
    },
    apollo: {
      workshops: gql `
      query{
        workshops{
          id
          Titel
          Seminarleiter
          datum
          uhrzeitVon
          uhrzeitBis
          beschreibung
          preis
          bild{
            url
            alternativeText
          }
        }
      }
      `
    }
  };
</script>
