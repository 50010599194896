import Vue from "vue";
import VueApollo from "vue-apollo";
import '@/assets/css/style.css'
import VueSimpleMarkdown from 'vue-simple-markdown'
import VModal from 'vue-js-modal'

import apolloClient from "./vue-apollo";
import Vuelidate from 'vuelidate'

import App from "./Yoga.vue";
import router from './router.js';

Vue.use(Vuelidate)
Vue.use(VueApollo);
Vue.use(VueSimpleMarkdown);
Vue.config.productionTip = false;
Vue.use(VModal);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});

new Vue({
  apolloProvider,
  router,
  render: h => h(App)
}).$mount("#app");
