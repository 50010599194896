<template>
  <nav id="navbar-yoga" class="navbar navbar-light bg-blue">
    <ul class="nav nav-pills">
      <li class="nav-item">
        <a class="nav-link nav-link-active" href="#main">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#about">About</a>
      </li>
      <li class="nav-item" v-if="filterNachrichtByActive().length >= 1">
        <a class="nav-link" href="#News">News</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#kurse">Kurse</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#PersonalYoga">Personal Yoga</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#hormonyoga">Hormonyoga</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#workshop">Seminare / Workshops</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#kontakt">Kontakt</a>
      </li>
    </ul>
  </nav>
</template>

<script>
  import gql from 'graphql-tag';
  import $ from 'jquery';

  export default {
    name: "Nav",

    data() {
      return {
        nachrichts: [],
        url: process.env.VUE_APP_STRAPI_API_URL
      };
    },
    methods: {
      filterNachrichtByActive() {
        return this.nachrichts.filter(nachrichts => nachrichts.aktiv == true)
      }
    },
    apollo: {
      nachrichts: gql `
      query {
        nachrichts{
          aktiv
        }
      }
      `
    }
  };

$(document).ready(function() {
  $("nav").on("click", ".nav-pills .nav-link" , function(){
    $(".nav").find(".nav-link-active").removeClass("nav-link-active");
    $(this).addClass("nav-link-active");
  });
});

$(document).ready(function(){
  // Add smooth scrolling to all links
  $("nav-link").on('click', function(event) {

    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();

      // Store hash
      var hash = this.hash;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 800, function(){
   
        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;
      });
    } // End if
  });
});

</script>
