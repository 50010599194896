<template>
<section id="PersonalYoga">
  <span class="background-headline-small">Personal Yoga</span>
  <div class="container-fluid">
    <h2 class="display-2">Personal Yoga</h2>
    <div class="row" id="personalYogaDesktop" style="justify-content:center;margin-top:10rem;">
      <div class="col personal-yoga-item" v-for="personalYoga in personalYogas" :key="personalYoga.id">
        <h3 class="personal-yoga-display">{{personalYoga.Titel}}</h3>
        <img :src="url + personalYoga.Cover.url" height="300">
        <p class="personal-yoga-qoute text-center">&ldquo;{{personalYoga.Zitat}}&rdquo;</p>
        <article class="">
          {{personalYoga.beschreibung}}
        </article>
        <div class="personal-yoga-fee">
          <b style="font-size:3rem;">Gebühr</b>
          <p>{{personalYoga.fee.Art}} - {{personalYoga.fee.Preis}} &euro;</p>
        </div>
      </div>
    </div>
    <div class="personal-yoga-slideshow">
      <carousel :perPage="1" :paginationEnabled="true" :scrollPerPage="true" :autoplay="false">
        <slide class="personal-yoga-slide" v-for="personalYoga in personalYogas" :key="personalYoga.id">
          <div class="col personal-yoga-item">
            <h3 class="personal-yoga-display">{{personalYoga.Titel}}</h3>
            <img :src="url + personalYoga.Cover.url" height="300">
            <p class="personal-yoga-qoute text-center">&ldquo;{{personalYoga.Zitat}}&rdquo;</p>
            <article class="">
              {{personalYoga.beschreibung}}
            </article>
            <div class="personal-yoga-fee">
              <b style="font-size:3rem;">Gebühr</b>
              <p>{{personalYoga.fee.Art}} - {{personalYoga.fee.Preis}} &euro;</p>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</section>
</template>

<script>
import {
  Carousel,
  Slide
} from 'vue-carousel';
import gql from 'graphql-tag';

export default {
  name: 'PersonalYoga',
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      personalYogas: [],
      url: process.env.VUE_APP_STRAPI_API_URL
    };
  },
  apollo: {
    personalYogas: gql `
    query{
      personalYogas{
        id
        Titel
        Cover{
          url
        }
        Zitat
        beschreibung
        fee{
          Art
          Preis
        }
      }
    }
    `
  }
}
</script>
